<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-form label-width="80px">
                <el-row>
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong style=" font-size: 16px; ">当前：供应商应付期初</strong>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                    <el-col :lg="24">
                        <el-row>
                            <el-col :lg="4">
                                <el-button style=" margin-top: 8px;margin-bottom: 8px; " type="primary" size="mini"
                                           @click="addEvent">添加供应商
                                </el-button>
                                <el-button style=" margin-top: 8px; " id="createBill" type="primary" round size="mini"
                                           @click="getList">搜索查询
                                </el-button>
                            </el-col>
                            <el-col :lg="4" :sm="12" :md="4">
                                <vxe-toolbar class="pull-left" style=" height: 42px; " import custom export ref="xToolbar2">
                                </vxe-toolbar>
                            </el-col>

                        </el-row>
                    </el-col>

                </el-row>
                <el-row>
                    <el-col :lg="24">
                        <vxe-grid ref="xGrid"
                                  size="mini"
                                  :import-config="importConfig"
                                  :export-config="exportConfig"
                                  height="650"
                                  border
                                  resizable
                                  show-overflow
                                  keep-source
                                  highlight-current-row
                                  :data="tableDataMain"
                                  :columns="tableColumns"
                                  :mouse-config="{selected: true}"
                                  :edit-config="{showIcon:false,trigger: 'dblclick', mode: 'cell', showStatus: false}"
                                  :keyboard-config="{enterToTab:true,isArrow: true, isDel: false, isEnter: true, isTab: true,isEdit:true}">
                            <!-- 修改应付期初 -->
                            <template #start_edit="{ row}">
                                <vxe-input @blur="saveOrUpdate(row)"
                                           v-model="row.start" type="float" clearable
                                           :controls="false"></vxe-input>
                            </template>
                            <template #pager>
                                <vxe-pager
                                        :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
                                        :page-sizes="tablePage.pageSizes"
                                        :current-page.sync="tablePage.currentPage"
                                        :page-size.sync="tablePage.pageSize"
                                        :total="tablePage.total"
                                        @page-change="handlePageChange">
                                </vxe-pager>
                            </template>
                        </vxe-grid>
                    </el-col>
                </el-row>
            </el-form>
        </el-main>
        <el-dialog width="1220px" :title="isErrorTitle ? '失败' : '导入数据'" :visible.sync="dialogFormVisible"
                   :close-on-click-modal="false">
            <vxe-grid
                    border
                    resizable
                    height="400"
                    ref="xGridImport"
                    :columns="tableImport"
                    :data="tableDataImport"
                    :edit-config="{trigger: 'click', mode: 'cell', icon: 'fa fa-pencil-square-o'}">
            </vxe-grid>
            <div slot="footer" class="dialog-footer">
                <template v-if="isErrorTitle">
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                </template>

                <el-button type="primary" @click="saveImport" v-show="!isErrorTitle">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog width="780px" :title="isAdd ?  '新增供应商信息' : '编辑供应商信息'" :visible.sync="showEdit"
                   :close-on-click-modal="false">
            <el-form ref="supplierForm" :rules="supplierFormRules" :model="supplierForm" label-width="100px">
                <el-row>
                    <el-col :lg="12" :md="12">
                        <el-form-item label="供应商名称" prop="supplier_name">
                            <el-input v-model="supplierForm.supplier_name" size="mini" style="width: 250px"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :lg="12" :md="12">
                        <el-form-item label="备注" prop="remark">
                            <el-input v-model="supplierForm.remark" size="mini" style="width: 250px"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :lg="12" :md="12">
                        <el-form-item label="联系人" prop="linkman">
                            <el-input v-model="supplierForm.linkman" size="mini" style="width: 250px"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :lg="12" :md="12">
                        <el-form-item label="联系人电话" prop="linkman_phone">
                            <el-input-number :controls="false" v-model="supplierForm.linkman_phone" size="mini"
                                             style="width: 250px"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-col :md="24">
                    <el-form-item label="曾用名" prop="usedName">
                        <el-input style="width: 100%" v-model="supplierForm.used_name" readonly size="mini"></el-input>
                    </el-form-item>
                </el-col>
                <el-row>
                    <el-col :lg="12" :md="12">
                        <el-form-item label="状态">
                            <el-switch active-value="激活" inactive-value="禁用" v-model="supplierForm.state"></el-switch>
                        </el-form-item>
                        <el-form-item label="启动每天限价">
                            <el-switch active-value="开启" inactive-value="关闭" v-model="supplierForm.limited_price"></el-switch>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="showEdit = false">取 消</el-button>
                <el-button type="primary" @click="addRoUpdateEditEvent">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import {filterRender} from "@/utils/gird-filter";


    export default {

        data() {
            return {
                showEdit: false,
                isAdd: false,
                uuid: '',
                isErrorTitle: '',
                tableImport: [
                    {field:'id', width: 100, title: '供应商编号'},
                    {field:'supplier_name', width: 250, title: '供应商名称'},
                    {field:'start', width: 120, title: '期初应付'},
                    {
                        field:'error', width: 190, title: '#', slots: {
                            default: ({row}) => {
                                return [
                                    <el-link type="danger">{row.error}</el-link>
                                ]
                           },
                       }
                   }
                ],
                tableDataImport: [],
                importConfig: {
                    remote: true,
                    importMethod: this.importMethod,
                    modes: ['insert'],
                    type: ['xlsx'],
                    types: ['xlsx']
               },
                exportConfig: {type: ['xlsx'], types: ['xlsx'],columns:[{field:'id'},{field:'supplier_name'},{field:'start'}]},
                border: true,
                resizable: true,
                keepSource: true,
                showOverflow: true,
                height: 650,
                loading: false,
                editConfig: {
                    // 设置触发编辑为手动模式
                    trigger: 'manual',
                    // 设置为整行编辑模式
                    mode: 'row',
                    // 显示修改状态和新增状态
                    showStatus: true,
                    // 自定义可编辑列头的图标
                    icon: 'fa fa-file-text-o'
               },
                tableColumns: [
                    {
                        field:'id', width: 100, title: '供应商编号', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/supplierStart/searchHeard')
                       }
                   },
                    {
                        field:'supplier_name', width: 320, title: '供应商名称', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/supplierStart/searchHeard')
                       }
                   },
                    {
                        field:'used_name', width: 320, title: '曾用名', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/supplierStart/searchHeard')
                        }
                    },
                    {
                        field:'state', width: 100, title: '状态', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/supplierStart/searchHeard')
                       }
                   },{ field:'start', width: 100 ,title: '期初应付' , formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', { digits: 2})
                       },slots:{
                            edit: 'start_edit',
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData,this.sortData, '/admin/supplierStart/searchHeard')
                       },
                        editRender: {autofocus: 'input.vxe-input--inner'}
                   },
                ],
                tableDataMain: [],

                dialogFormVisible: false,
                tablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500,
                    pageSizes: [ 500,1000,2500,5000]
               },
                filterData: {},
                sortData: {"id":"asc"},
                supplierForm: {
                    id: '',
                    supplier_name: '',
                    linkman: '',
                    linkman_phone: '',
                    remark: '',
                    used_name: '',
                    state: '激活',
                    limited_price: '关闭',
               },
                supplierFormRules: {
                    supplier_name: [
                        {required: true, message: '请选输入供应商名称', trigger: 'blur'}
                    ],
                    linkman: [
                        {required: true, message: '请选输入联系人', trigger: 'blur'}
                    ],
                    linkman_phone: [
                        {required: true, message: '请选输入联系人电话', trigger: 'blur'}
                    ],
                    remark: [
                        {required: false, message: '请选输入备注', trigger: 'blur'}
                    ],

               },
                activeName: 'first',
                remvoeIds: [],
                banIds: [],
                changeId: '',
                account: [],
           }
       },
        methods: {
            saveOrUpdate(row){
                this.$axios({
                    method: 'post',
                    url: '/admin/supplierStart/saveOrUpdate',
                    params: {
                        id: row.id,
                        start: row.start,
                   }
               }).then((response) => {          //这里使用了ES6的语法
                    //(response)       //请求成功返回的数据
                    //console.log(response)
                    if (response.status == 200 && response.data.state == "ok") {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'success'
                       });
                   } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        this.getList();
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },

            addRoUpdateEditEvent() {
                let url = '';
                if (this.isAdd) {
                    url = '/admin/supplier/saveVue';
               } else {
                    url = '/admin/supplier/updateVue';
               }
                this.$refs.supplierForm.validate((valid) => {
                    if (valid) {
                        this.$axios({
                            method: 'post',
                            url: url,
                            data: this.supplierForm,
                       }).then((response) => {          //这里使用了ES6的语法
                            //console.log(response)       //请求成功返回的数据
                            if (response.status === 200 && response.data.state == 'ok') {
                                this.$message({
                                    showClose: true,
                                    message: '保存成功',
                                    type: 'success'
                               });
                                this.showEdit = false;
                                this.getList();
                           } else {
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'error'
                               });
                                return
                           }
                       }).catch((error) => {
                            console.log(error)
                       });
                   } else {
                        //console.log('error submit!!');
                        return false;
                   }
               });
           },
            addEvent() {
                this.showEdit = true
                this.isAdd = true
                this.supplierForm.id = '';
                this.supplierForm.supplier_name = '';
                this.supplierForm.linkman = '';
                this.supplierForm.linkman_phone = '';
                this.supplierForm.remark = '';
                this.supplierForm.used_name = '';
                this.supplierForm.state = '激活';
           },

            getList() {
                this.$axios({
                    method: 'POST',
                    url: '/admin/supplierStart/getList',
                    data: {
                        currentPage: this.tablePage.currentPage,
                        pageSize: this.tablePage.pageSize,
                        sortData: this.sortData,
                        filterData: this.filterData,
                   }
               }).then((response) => {          //这里使用了ES6的语法
                    console.log(response)       //请求成功返回的数据
                    if (response.status === 200) {
                        this.tableDataMain = response.data.page.list
                        this.tablePage.pageSize = response.data.page.pageSize
                        this.tablePage.total = response.data.page.totalRow
                        this.tablePage.currentPage = response.data.page.pageNumber
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
            saveImport() {
                this.$axios({
                    method: 'post',
                    url: '/admin/supplierStart/dbSaveImport',
                    params: {
                        uuid: this.uuid
                   }
               }).then((response) => {          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.$message({
                            showClose: true,
                            message: '导入成功',
                            type: 'success'
                       });
                        this.dialogFormVisible = false
                   } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
                    this.getList();
               }).catch((error) => {
                    console.log(error)
               });
           },
            importMethod({file}) {
                const loading = this.$loading({
                    lock: true,
                    text: '导入中，请喝杯水，耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                const formBody = new FormData()
                formBody.append('file', file)
                this.$axios({
                    method: 'post',
                    url: '/admin/supplierStart/importExcel',
                    data: formBody,
               }).then((response) => {          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    loading.close();
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.isErrorTitle = response.data.isError
                        if (response.data.isError == true) {
                            this.tableDataImport = response.data.supplierListError
                       } else {
                            this.tableDataImport = response.data.supplierList

                       }
                        this.uuid = response.data.uuid
                        this.dialogFormVisible = true;
                   } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) => {
                    loading.close();
                    console.log(error)
               });
           },
            handlePageChange({currentPage, pageSize}) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                this.getList()
           },

            saveRowEvent() {
                const $grid = this.$refs.xGrid
                $grid.clearActived().then(() => {
                    this.loading = true
                    setTimeout(() => {
                        this.loading = false
                        this.$XModal.message({content: '保存成功！', status: 'success'})
                   }, 300)
               })
           },

       },
        created() {

            this.$nextTick(() => {
                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2)
                this.getList()
           })
       }
   };


</script>

<style scoped>
    .header-wrapepr {
        display: flex;
        flex-direction: row;
        justcontent: space-between
   }

    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 130px;
   }

    /deep/ .el-form-item__error {
        position: initial !important;
   }

    .el-form-item__content .el-input {
        width: 130px;
   }

    /deep/ .vxe-input--panel {
        position: fixed !important;
        left: auto;
   }

    /deep/ .el-input-number .el-input__inner {
        text-align: initial !important;
   }

    .el-form-item__content .el-input-group {
        vertical-align: initial;
   }
</style>
